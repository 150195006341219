import {
  GlobalStyles,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {Outlet} from 'react-router-dom';
import {ScrollToTop} from '../utils/ScrollToTop';
import {
  ApiStoreLink,
  BlogLink,
  ContactLink,
  DocumentationLink,
  OnlineApiTestLink,
  PricingLink,
} from '../utils/constants';
import {Link} from './Link';
import {Footer} from './Footer';
import {Image} from './Image';

const headerLinksSx: SxProps<Theme> = {
  position: 'relative',
  color: 'white',
  fontWeight: 'bold',
  '&::after': {
    position: 'absolute',
    content: '""',
    width: 10,
    height: 0,
    background: 'white',
    left: '50%',
    bottom: -10,
    transform: 'translateX(-50%)',
    transition: 'all .1s linear',
  },
  '&:hover': {
    '&::after': {
      width: 20,
      height: 2,
      bottom: -8,
    },
  },
};

export const Layout = ({
  children,
  dark,
}: {
  children?: React.ReactNode;
  dark?: boolean;
}) => {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  const globalStyles = dark ? (
    <GlobalStyles
      styles={{
        body: {
          backgroundColor: theme.palette.background.paper,
        },
      }}
    />
  ) : null;

  return (
    <>
      <ScrollToTop />
      {globalStyles}
      <Box
        sx={
          tablet
            ? undefined
            : {
                position: 'sticky',
                top: 0,
                bgcolor: 'background.default',
                zIndex: 100,
              }
        }
      >
        <Container>
          {/* HEADER */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              py: 2,
              flexWrap: tablet ? 'wrap' : 'nowrap',
            }}
          >
            <Link
              to="/"
              ariaLabel="Go to ScrapingBot home page"
              sx={
                tablet
                  ? {
                      flex: '0 0 100%',
                      textAlign: 'center',
                    }
                  : {}
              }
            >
              <Image
                src="logo.webp"
                alt="ScrapingBot"
                sizes={[300]}
                width={450}
                height={83}
                style={{width: 190}}
              />
            </Link>
            <Box
              component="nav"
              sx={
                tablet
                  ? {
                      flex: '0 0 100%',
                      textAlign: 'center',
                    }
                  : {}
              }
            >
              <Box
                component="ul"
                sx={{
                  display: 'inline-flex',
                  listStyle: 'none',
                  gap: 4,
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  pl: 0,
                }}
              >
                <li>
                  <Link to={`/${ApiStoreLink}`} sx={headerLinksSx}>
                    API Store
                  </Link>
                </li>
                <li>
                  <Link to={`/${OnlineApiTestLink}`} sx={headerLinksSx}>
                    Live Test
                  </Link>
                </li>
                <li>
                  <Link to={`/${PricingLink}`} sx={headerLinksSx}>
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link to={`/${DocumentationLink()}`} sx={headerLinksSx}>
                    Documentation
                  </Link>
                </li>
                <li>
                  <Link to={`/${BlogLink}`} sx={headerLinksSx}>
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to={`/${ContactLink}`} sx={headerLinksSx}>
                    Contact us
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.scraping-bot.io/dashboard/"
                    sx={headerLinksSx}
                  >
                    Dashboard
                  </Link>
                </li>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box component="main">{children ?? <Outlet />}</Box>
      {/* FOOTER */}
      <Footer />
    </>
  );
};
