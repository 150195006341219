import {lazy} from 'react';

export const LazyApiStore = lazy(() => import('../../views/ApiStore'));

export const LazyBlog = lazy(() => import('../../views/Blog'));

export const LazyBlogPost = lazy(() => import('../../views/BlogPost'));

export const LazyContact = lazy(() => import('../../views/Contact'));

export const LazyDocumentation = lazy(
  () => import('../../views/Documentation')
);
export const LazyErrorPage = lazy(() => import('../../views/ErrorPage'));

export const LazyHome = lazy(() => import('../../views/Home'));

export const LazyOnlineApiTest = lazy(
  () => import('../../views/OnlineApiTest')
);

export const LazyPricing = lazy(() => import('../../views/Pricing'));

export const LazyRawHtmlScraper = lazy(
  () => import('../../data/scrapers/RawHtmlScraper')
);

export const LazySearchEngineScraper = lazy(
  () => import('../../data/scrapers/SearchEngineScraper')
);

export const LazyEcommerceScraper = lazy(
  () => import('../../data/scrapers/EcommerceScraper')
);

export const LazyRealEstateScraper = lazy(
  () => import('../../data/scrapers/RealEstateScraper')
);

export const LazyLinkedInScraper = lazy(
  () => import('../../data/scrapers/LinkedInScraper')
);

export const LazyInstagramScraper = lazy(
  () => import('../../data/scrapers/InstagramScraper')
);

export const LazyFacebookScraper = lazy(
  () => import('../../data/scrapers/FacebookScraper')
);

export const LazyTiktokScraper = lazy(
  () => import('../../data/scrapers/TiktokScraper')
);

export const LazyThreadsScraper = lazy(
  () => import('../../data/scrapers/ThreadsScraper')
);

export const LazyTwitterScraper = lazy(
  () => import('../../data/scrapers/TwitterScraper')
);

export const LazyYieldbooking = lazy(
  () => import('../../data/scrapers/YieldbookingScraper')
);

export const LazyPrestashop = lazy(
  () => import('../../data/scrapers/PrestashopScraper')
);

export const LazyFundaScraper = lazy(
  () => import('../../data/scrapers/FundaScraper')
);

export const LazyCdiscountScraper = lazy(
  () => import('../../data/scrapers/CdiscountScraper')
);

export const LazyAmazonScraper = lazy(
  () => import('../../data/scrapers/AmazonScraper')
);

export const LazyRakutenScraper = lazy(
  () => import('../../data/scrapers/RakutenScraper')
);

export const LazyFnacScraper = lazy(
  () => import('../../data/scrapers/FnacScraper')
);

export const LazySephoraScraper = lazy(
  () => import('../../data/scrapers/SephoraScraper')
);

export const LazyLeboncoinScraper = lazy(
  () => import('../../data/scrapers/LeboncoinScraper')
);

export const LazyWoodbrassScraper = lazy(
  () => import('../../data/scrapers/WoodbrassScraper')
);

export const LazyFossilScraper = lazy(
  () => import('../../data/scrapers/FossilScraper')
);

export const LazySubitoScraper = lazy(
  () => import('../../data/scrapers/SubitoScraper')
);

export const LazyBestbuyScraper = lazy(
  () => import('../../data/scrapers/BestbuyScraper')
);

export const LazyZaraScraper = lazy(
  () => import('../../data/scrapers/ZaraScraper')
);

export const LazyGogScraper = lazy(
  () => import('../../data/scrapers/GogScraper')
);

export const LazyMaisonDuMondeScraper = lazy(
  () => import('../../data/scrapers/MaisonDuMondeScraper')
);

export const LazyImmobilienscoutScraper = lazy(
  () => import('../../data/scrapers/ImmobilienscoutScraper')
);

export const LazyDellScraper = lazy(
  () => import('../../data/scrapers/DellScraper')
);

export const LazyBoseScraper = lazy(
  () => import('../../data/scrapers/BoseScraper')
);

export const LazyBoulangerScraper = lazy(
  () => import('../../data/scrapers/BoulangerScraper')
);

export const LazyBolScraper = lazy(
  () => import('../../data/scrapers/BolScraper')
);

export const LazyLaRedouteScraper = lazy(
  () => import('../../data/scrapers/LaRedouteScraper')
);

export const LazyRightmoveScraper = lazy(
  () => import('../../data/scrapers/RightmoveScraper')
);

export const LazyHardwareScraper = lazy(
  () => import('../../data/scrapers/HardwareScraper')
);

export const LazyIdealistaScraper = lazy(
  () => import('../../data/scrapers/IdealistaScraper')
);

export const LazyImmowebScraper = lazy(
  () => import('../../data/scrapers/ImmowebScraper')
);

export const LazyBienIciScraper = lazy(
  () => import('../../data/scrapers/BienIciScraper')
);

export const LazySeLogerScraper = lazy(
  () => import('../../data/scrapers/SeLogerScraper')
);

export const LazyKaufpartsScraper = lazy(
  () => import('../../data/scrapers/KaufpartsScraper')
);

export const LazyCraigslistScraper = lazy(
  () => import('../../data/scrapers/CraigslistScraper')
);

export const LazyIGVaultScraper = lazy(
  () => import('../../data/scrapers/IGVaultScraper')
);

export const LazyEntreParticuliersScraper = lazy(
  () => import('../../data/scrapers/EntreParticuliersScraper')
);

export const LazyMaterielNetScraper = lazy(
  () => import('../../data/scrapers/MaterielNetScraper')
);

export const LazyEbayScraper = lazy(
  () => import('../../data/scrapers/EbayScraper')
);

export const LazyAliexpressScraper = lazy(
  () => import('../../data/scrapers/AliexpressScraper')
);

export const LazyFacebookPostScraper = lazy(
  () => import('../../data/scrapers/FacebookPostScraper')
);

export const LazyFacebookJobsScraper = lazy(
  () => import('../../data/scrapers/FacebookJobsScraper')
);

export const LazyFacebookBusinessPageScraper = lazy(
  () => import('../../data/scrapers/FacebookBusinessPageScraper')
);

export const LazyFacebookProfileScraper = lazy(
  () => import('../../data/scrapers/FacebookProfileScraper')
);

export const LazyLinkedInPostScraper = lazy(
  () => import('../../data/scrapers/LinkedInPostScraper')
);

export const LazyLinkedInBusinessProfilesScraper = lazy(
  () => import('../../data/scrapers/LinkedInBusinessProfilesScraper')
);

export const LazyLinkedinUrlsScraper = lazy(
  () => import('../../data/scrapers/LinkedinUrlsScraper')
);

export const LazyLinkedinProfileScraper = lazy(
  () => import('../../data/scrapers/LinkedinProfileScraper')
);

export const LazyInstagramPostScraper = lazy(
  () => import('../../data/scrapers/InstagramPostScraper')
);

export const LazyInstagramHashtagsScraper = lazy(
  () => import('../../data/scrapers/InstagramHashtagsScraper')
);

export const LazyInstagramProfilesScraper = lazy(
  () => import('../../data/scrapers/InstagramProfilesScraper')
);

export const LazyTiktokProfilesScraper = lazy(
  () => import('../../data/scrapers/TiktokProfilesScraper')
);

export const LazyTiktokHashtagsScraper = lazy(
  () => import('../../data/scrapers/TiktokHashtagsScraper')
);

export const LazyTravelScraper = lazy(
  () => import('../../data/scrapers/TravelScraper')
);

export const LazyAdidasScraper = lazy(
  () => import('../../data/scrapers/AdidasScraper')
);

export const LazyNikeScraper = lazy(
  () => import('../../data/scrapers/NikeScraper')
);

export const LazyPapScraper = lazy(
  () => import('../../data/scrapers/PapScraper')
);

export const LazyPropertyPassBookScraper = lazy(
  () => import('../../data/scrapers/PropertyPassBookScraper')
);

export const LazyZillowScraper = lazy(
  () => import('../../data/scrapers/ZillowScraper')
);

export const LazyParuVenduScraper = lazy(
  () => import('../../data/scrapers/ParuVenduScraper')
);

export const LazyTruliaScraper = lazy(
  () => import('../../data/scrapers/TruliaScraper')
);

export const LazyRealtorScraper = lazy(
  () => import('../../data/scrapers/RealtorScraper')
);

export const LazyImmoDiffusionScraper = lazy(
  () => import('../../data/scrapers/ImmoDiffusionScraper')
);

export const LazyApartmentsScraper = lazy(
  () => import('../../data/scrapers/ApartmentsScraper')
);

export const LazyOuestfranceImmoScraper = lazy(
  () => import('../../data/scrapers/OuestFranceImmoScraper')
);

export const LazyAbritelScraper = lazy(
  () => import('../../data/scrapers/AbritelScraper')
);

export const LazyExpediaScraper = lazy(
  () => import('../../data/scrapers/ExpediaScraper')
);

export const LazyAirbnbScraper = lazy(
  () => import('../../data/scrapers/AirbnbScraper')
);

export const LazyHotelsScraper = lazy(
  () => import('../../data/scrapers/HotelsScraper')
);

export const LazyGitesDeFranceScraper = lazy(
  () => import('../../data/scrapers/GitesDeFranceScraper')
);

export const LazyBookingScraper = lazy(
  () => import('../../data/scrapers/BookingScraper')
);

export const LazyInstantGamingScraper = lazy(
  () => import('../../data/scrapers/InstantGamingScraper')
);

export const LazyAorusScraper = lazy(
  () => import('../../data/scrapers/AorusScraper')
);

export const LazyMacwayScraper = lazy(
  () => import('../../data/scrapers/MacwayScraper')
);

export const LazyShopbopScraper = lazy(
  () => import('../../data/scrapers/ShopbopScraper')
);

export const LazyMicromaniaScraper = lazy(
  () => import('../../data/scrapers/MicromaniaScraper')
);

export const LazyPixmaniaScraper = lazy(
  () => import('../../data/scrapers/PixmaniaScraper')
);

export const LazyZalandoScraper = lazy(
  () => import('../../data/scrapers/ZalandoScraper')
);

export const LazyWalmartScraper = lazy(
  () => import('../../data/scrapers/WalmartScraper')
);

export const LazyCultureVeloScraper = lazy(
  () => import('../../data/scrapers/CultureVeloScraper')
);

export const LazyConforamaScraper = lazy(
  () => import('../../data/scrapers/ConforamaScraper')
);

export const LazyNikonScraper = lazy(
  () => import('../../data/scrapers/NikonScraper')
);

export const LazyPlaymobilScraper = lazy(
  () => import('../../data/scrapers/PlaymobilScraper')
);

export const LazyLambdaTekScraper = lazy(
  () => import('../../data/scrapers/LambdaTekScraper')
);

export const LazyCanadianTireScraper = lazy(
  () => import('../../data/scrapers/CanadianTireScraper')
);

export const LazyDysonScraper = lazy(
  () => import('../../data/scrapers/DysonScraper')
);

export const LazyCartersScraper = lazy(
  () => import('../../data/scrapers/CartersScraper')
);

export const LazyBrownsFashionScraper = lazy(
  () => import('../../data/scrapers/BrownsFashionScraper')
);

export const LazyNetAPorterScraper = lazy(
  () => import('../../data/scrapers/NetAPorterScraper')
);

export const LazyNeimanMarcusScraper = lazy(
  () => import('../../data/scrapers/NeimanMarcusScraper')
);

export const LazyJohnLewisScraper = lazy(
  () => import('../../data/scrapers/JohnLewisScraper')
);

export const LazySheinScraper = lazy(
  () => import('../../data/scrapers/SheinScraper')
);

export const LazyG2AScraper = lazy(
  () => import('../../data/scrapers/G2AScraper')
);

export const LazylacompagniedurhumScraper = lazy(
  () => import('../../data/scrapers/LaCompagnieDuRhumScraper')
);

export const LazyBonsPlansEasycashScraper = lazy(
  () => import('../../data/scrapers/BonsPlansEasycashScraper')
);

export const LazyTopachatScraper = lazy(
  () => import('../../data/scrapers/TopachatScraper')
);

export const LazyRueDuCommerceScraper = lazy(
  () => import('../../data/scrapers/RueDuCommerceScraper')
);

export const LazyJoueclubScraper = lazy(
  () => import('../../data/scrapers/JoueclubScraper')
);

export const LazyLoisirs3000Scraper = lazy(
  () => import('../../data/scrapers/Loisirs3000Scraper')
);

export const LazyFarfetchScraper = lazy(
  () => import('../../data/scrapers/FarfetchScraper')
);

export const LazyEpsonScraper = lazy(
  () => import('../../data/scrapers/EpsonScraper')
);

export const LazyJdSportsScraper = lazy(
  () => import('../../data/scrapers/JdsportsScraper')
);

export const LazyAlibabaScraper = lazy(
  () => import('../../data/scrapers/AlibabaScraper')
);

export const LazyPlaystationScraper = lazy(
  () => import('../../data/scrapers/PlaystationScraper')
);

export const LazyTrianglScraper = lazy(
  () => import('../../data/scrapers/TrianglScraper')
);

export const LazyBmxshopScraper = lazy(
  () => import('../../data/scrapers/BmxshopScraper')
);

export const LazyEmailScraping = lazy(
  () => import('../../data/posts/EmailScraping')
);

export const LazyPricingWeb = lazy(() => import('../../data/posts/PricingWeb'));

export const LazyHowToScrapeThreads = lazy(
  () => import('../../data/posts/HowToScrapeThreads')
);

export const LazyScrapingBotRealEstate = lazy(
  () => import('../../data/posts/ScrapingBotRealEstate')
);

export const LazyBestWebScrapingTools = lazy(
  () => import('../../data/posts/BestWebScrapingTools')
);

export const LazyHowToScrapeTwitter = lazy(
  () => import('../../data/posts/HowToScrapeTwitter')
);

export const LazyDataSportsBetting = lazy(
  () => import('../../data/posts/DataSportsBetting')
);
